export interface BusinessPartner {
  id: string;
  name: string;
}

//TODO type should be enum LANGUAGE
export interface UserPreferences {
  type: string;
  value: string;
}

export interface CustomerService {
  description: string;
  email: string;
  phone: string;
  openingHours: string[];
  callCharge: string;
  countryCode: string;
}

export interface User {
  uuid: string;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  agreementAccepted: boolean;
  preferences: UserPreferences[];
  businessPartners: BusinessPartner[];
  isInternal: boolean;
  isAdmin: boolean;
  isOrdersManagementAllowed: boolean;
  customerService: CustomerService;
}

export const isUser = (user: unknown): user is User =>
  typeof user === 'object' && user !== null && 'name' in user && 'lastname' in user && 'email' in user;

export type InternalAdminAccess = {
  isInternal: true;
  isAdmin: true;
  selectedBusinessPartners: BusinessPartner[];
};
export type UserAccess = { isInternal: boolean; isAdmin: false } | InternalAdminAccess;

export interface PasswordChangeUrlResponse {
  ticketUrl: string;
}
