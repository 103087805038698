import { User } from '@models/user';
import { isUserInternalAdmin } from '@utils/user';

export const ordersCreationToggleOn = process.env.REACT_APP_ORDER_CREATION_ALLOWED === 'true';

export const co2ReportToggleOn = process.env.REACT_APP_CO2_REPORT_ALLOWED === 'true';

export const ordersManagementToggleOn = (userInfo: User) => userInfo.isOrdersManagementAllowed;

export const usersManagementToggleOn = (user: User) =>
  process.env.REACT_APP_USERS_MANAGEMENT_ALLOWED === 'true' && isUserInternalAdmin(user);
