import { IconFiles } from '@fortum/elemental-ui';
import { paths } from '@config/routes';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { FC } from 'react';
import { LinksDirection, Tile } from './Tile';

interface ReportsTileProps {
  linksDirection?: LinksDirection;
}

export const ReportsTile: FC<ReportsTileProps> = ({ linksDirection = 'horizontal' }) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'home']);

  return (
    <Tile
      linksDirection={linksDirection}
      icon={<IconFiles />}
      header={t('home:quickAccess.reports.header')}
      description={t('home:quickAccess.reports.desc')}
      links={[
        {
          id: 'dashboard-forward-link',
          description: t('reports:dashboard.navigation'),
          path: paths.dashboardReport,
        },
        {
          id: 'waste-details-report-forward-link',
          description: t('reports:wasteQty.navigation'),
          path: paths.wasteDetailsReport,
        },
        {
          id: 'cost-details-report-forward-link',
          description: t('reports:cost.navigation'),
          path: paths.costDetailsReport,
        },
      ]}
    />
  );
};
