import { SupportedLanguage, fallbackLang } from '@common/languages';
import { QlikReportConfig, ReportDefaultSelections, ReportIds } from './types';
import { qlikAppConfig } from './connection';
import { getUserAccessInfo } from '@utils/user';

//TODO: Allow passing configs through env variables, same as in the CO2 report

// TODO: uncomment when Norwegian translations will be finished. Add Norwegian to CompanyNameSelectionFieldName
const DashboardReports: ReportIds = {
  en: process.env.REACT_APP_DASHBOARD_REPORT_ID_EN ?? '',
  fi: process.env.REACT_APP_DASHBOARD_REPORT_ID_FI ?? '',
  sv: process.env.REACT_APP_DASHBOARD_REPORT_ID_SV ?? '',
  // nb: process.env.REACT_APP_DASHBOARD_REPORT_ID_NO ?? '',
};

export const DashboardDefaultSelections: ReportDefaultSelections = {
  en: [
    {
      fieldName: 'Company info',
      values: ['Company city', 'Company name'],
    },
    {
      fieldName: 'Measures',
      values: ['Waste quantity', 'Total costs', 'Credits for materials', 'Costs'],
    },
    {
      fieldName: 'TASK YEAR',
      values: [`${new Date().getFullYear()}`],
    },
  ],
  fi: [
    {
      fieldName: 'Yrityksen tiedot',
      values: ['Yrityksen nimi', 'Yrityksen paikkakunta'],
    },
    {
      fieldName: 'Kustannukset ja määrät',
      values: ['Jätemäärä', 'Kustannukset', 'Materiaalihyvitykset', 'Kokonaiskustannukset'],
    },
    {
      fieldName: 'TASK YEAR',
      values: [`${new Date().getFullYear()}`],
    },
  ],
  sv: [
    { fieldName: 'Företagsinformation', values: ['Adress', 'Ort'] },
    { fieldName: 'Kostnader & mängder', values: ['Avfallsmängd', 'Totala kostnader'] },
    {
      fieldName: 'TASK YEAR',
      values: [`${new Date().getFullYear()}`],
    },
  ],
  /*
  nb: [
    { fieldName: 'Firma informasjon', values: ['Adresse', 'By'] },
    { fieldName: 'Kostnader & mengder', values: ['Avfallsmengde', 'Totale kostnader'] },
    {
      fieldName: 'TASK YEAR',
      values: [`${new Date().getFullYear()}`],
    },
  ],
  */
};

const CompanyNameSelectionFieldName: { [key in SupportedLanguage]: string } = {
  en: 'Company name',
  fi: 'Yrityksen nimi',
  sv: 'Företagsnamn',
};
export interface DashboardReportObjects {
  buttons: {
    switchToMonthly: string;
    switchToCumulative: string;
  };
  filters: {
    filterByCurrentYear: string;
    filterByLastYear: string;
    filterByLastLastYear: string;
    company: string;
    hazardous: string;
  };
  charts: {
    costsAndCreditsOverTime: string;
    receivedMaterialOverTime: string;
    wasteHandling: string;
    costsInEuros: string;
    costByServiceGroup: string;
  };
  tables: {
    summaryByCustomerSite: string | undefined;
    receivedMaterialOverTime: string;
    summaryByWaste: string;
  };
  dynamicTable: {
    table: string;
    dimensions: {
      company: string;
      wasteDetails: string;
      measures: string;
    };
  };
}

const ReportCommonObjectsIds: DashboardReportObjects = {
  buttons: {
    switchToMonthly: 'bNMdqgt',
    switchToCumulative: 'xDEFG',
  },
  filters: {
    filterByCurrentYear: 'ndDpBpC',
    filterByLastYear: 'hybqTmm',
    filterByLastLastYear: 'ueHALDw',
    company: 'hhcFD',
    hazardous: 'FRNQmj',
  },
  charts: {
    costsAndCreditsOverTime: 'ekMj',
    receivedMaterialOverTime: 'yyuJzm',
    wasteHandling: 'MaMhCuP',
    costsInEuros: 'QeuFbFJ',
    costByServiceGroup: 'xJBvjc',
  },
  tables: {
    summaryByCustomerSite: 'HABj',
    receivedMaterialOverTime: 'JKsAJPG',
    summaryByWaste: 'kDfJNqG',
  },
  dynamicTable: {
    table: 'hSfXvnb',
    dimensions: {
      company: 'CvXsZGq',
      wasteDetails: 'VhWnK',
      measures: 'cmJjss',
    },
  },
};

const ReportsObjectsIdsPerLang: { [key in SupportedLanguage]: DashboardReportObjects } = {
  en: { ...ReportCommonObjectsIds },
  fi: { ...ReportCommonObjectsIds },
  sv: {
    ...ReportCommonObjectsIds,
    tables: { ...ReportCommonObjectsIds.tables, summaryByCustomerSite: undefined },
  },
  /*
  nb: {
    ...ReportCommonObjectsIds,
    tables: { ...ReportCommonObjectsIds.tables, summaryByCustomerSite: undefined },
  },
  */
};

export const getDashboardReportConfig = (lang: SupportedLanguage): QlikReportConfig<DashboardReportObjects> => {
  const reportLanguage: SupportedLanguage = DashboardReports[lang] ? lang : fallbackLang;

  const staticConfig = {
    qlikAppConfig: {
      ...qlikAppConfig,
      appId: DashboardReports[reportLanguage],
    },
    defaultSelections: DashboardDefaultSelections[reportLanguage],
    objectIds: ReportsObjectsIdsPerLang[reportLanguage],
  };

  const userAccessInfo = getUserAccessInfo();

  if (!(userAccessInfo.isInternal && userAccessInfo.isAdmin)) {
    return staticConfig;
  }

  return {
    ...staticConfig,
    mandatorySelections: [
      {
        fieldName: CompanyNameSelectionFieldName[reportLanguage],
        values: userAccessInfo.selectedBusinessPartners.map(bp => bp.name),
      },
    ],
  };
};
