import { FC } from 'react';
import { useContractDetails } from '@data/hooks/useContractDetails';
import { Box, Col, Grid, Row, colors, spacing } from '@fortum/elemental-ui';
import { GRID_GAP, gridMarginHorizontal, gridMaxWidth, gridWidth } from '@routes/serviceDetails/style';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CenteredLoader } from '@components/layout/styles';
import { FailedToLoad } from '@components/layout/FailedToLoad';
import { useTranslation } from 'react-i18next';
import { ContractDetailsTile, ServiceDetailsWithWasteTiles, ServiceDetailsTiles } from './components';

import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import { PageNavigationHeader, PageNavigationHeaderProps } from '@components/index';
import { destructureContractIdentifier } from '@utils/routes';
import { containsWasteDetails, isEquipmentSalesService } from '@utils/contract';

const ServiceDetails: FC = () => {
  const { contractIdentifier } = useParams();
  const navigate = useNavigate();
  const { state } = useLocation();

  const { contractNo, dataSource } = destructureContractIdentifier(contractIdentifier);

  const { i18n, t } = useTranslation<Namespace>('wasteDetails');
  const { data: contract, isLoading, fetchStatus, error } = useContractDetails(contractNo, dataSource, i18n.language);

  if (isLoading || fetchStatus !== 'idle') {
    return <CenteredLoader />;
  } else if (error || !contract) {
    return <FailedToLoad />;
  }

  const isEquipmentSales = isEquipmentSalesService(contract.generalInfo.serviceType);

  const displayWasteDetails = containsWasteDetails(contract);

  const headerLinkProps: PageNavigationHeaderProps['link'] = {
    id: 'navigate-to-services-link',
    description: t('services'),
    onClick: () => {
      navigate(state?.navigateBackPath || paths.services);
    },
  };

  return (
    <Box backgroundColor={colors.snowWhite} height="100%">
      <Grid
        marginHorizontal={gridMarginHorizontal}
        maxWidth={gridMaxWidth}
        width={gridWidth}
        paddingHorizontal={{ xs: 0 }}
        mb={spacing.s}
      >
        <Row mb={spacing.xxs}>
          <Col xs={12}>
            <PageNavigationHeader link={headerLinkProps} title={t('wasteServiceDetails')} />
          </Col>
        </Row>

        <Row mb={GRID_GAP}>
          <Col xs={12}>
            <ContractDetailsTile
              generalInfo={contract.generalInfo}
              numberOfOrders={contract.ordersDetails.numberOfOrders}
              contractIdentifier={contractIdentifier!}
            />
          </Col>
        </Row>
        {displayWasteDetails ? (
          <ServiceDetailsWithWasteTiles contract={contract} isEquipmentSales={isEquipmentSales} />
        ) : (
          <ServiceDetailsTiles contract={contract} isEquipmentSales={isEquipmentSales} />
        )}
      </Grid>
    </Box>
  );
};

export default ServiceDetails;
