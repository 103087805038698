import { ContentText, ComponentOrElement, IconProps, renderComponentOrElement, Link } from '@fortum/elemental-ui';
import { FC } from 'react';
import { Header, headerTextStyles, iconStyles, LinksContainer, linkStyles, messageTextStyles, TileContainer } from './styles';
import { Path } from '@config/routes';
import { useNavigate } from 'react-router-dom';

interface LinkProps {
  id: string;
  description: string;
  path: Path;
}

export type LinksDirection = 'horizontal' | 'vertical';

interface TileProps {
  icon: ComponentOrElement<IconProps>;
  header: string;
  description: string;
  links: LinkProps[];
  linksDirection?: LinksDirection;
}

export const Tile: FC<TileProps> = ({ icon, header, description, links, linksDirection = 'horizontal' }) => {
  const navigate = useNavigate();

  return (
    <TileContainer>
      <Header>
        {renderComponentOrElement(icon, { ...iconStyles })}
        <ContentText {...headerTextStyles}>{header}</ContentText>
      </Header>

      <ContentText {...messageTextStyles}>{description}</ContentText>

      <LinksContainer $linksDirection={linksDirection}>
        {links.map(link => (
          <Link key={link.id} id={link.id} onClick={() => navigate(link.path)} {...linkStyles}>
            {link.description}
          </Link>
        ))}
      </LinksContainer>
    </TileContainer>
  );
};
