import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalContainerWithSmallGap, VerticalListItemWithPadding } from './styles';
import { InvoicingAddress } from '@components/invoicingDetails';
import { getInvoicingAddressElements } from '@utils/dataOperations/address';
import { InputField } from '@components/InputField';
import { hasValue } from '@utils/dataOperations';
import { SingleOrderCreationContext } from '../SingleOrderCreationContextProvider';

const ORDER_LEVEL_REFERENCE_MAX_LENGTH_CHARS = 20;

export const InvoicingDetailsForm: FC = () => {
  const { t } = useTranslation<Namespace>('wasteDetails');
  const { form, contract, setOrderLevelReference } = useContext(SingleOrderCreationContext);

  const { invoicingDetails } = contract.contractDetails;

  const addressElements = getInvoicingAddressElements(invoicingDetails.address);

  return (
    <Tile header={t('invoicingDetails.details')} data-testid={getTileTestId('invoicing-details')}>
      <VerticalContainerWithSmallGap>
        <VerticalListItemWithPadding
          label={t('invoicingDetails.invoicingAddress')}
          data-testid={getListItemTestId('invoicing-address')}
        >
          <InvoicingAddress addressElements={addressElements} />
        </VerticalListItemWithPadding>

        {hasValue(invoicingDetails.address.eInvoiceAddress) && (
          <VerticalListItemWithPadding
            label={t('invoicingDetails.eInvoiceAddress')}
            data-testid={getListItemTestId('e-invoice-address')}
          >
            {invoicingDetails.address.eInvoiceAddress}
          </VerticalListItemWithPadding>
        )}

        {hasValue(invoicingDetails.customerReference) && (
          <VerticalListItemWithPadding
            label={t('invoicingDetails.companyReference')}
            data-testid={getListItemTestId('company-reference')}
          >
            {invoicingDetails.customerReference}
          </VerticalListItemWithPadding>
        )}

        {hasValue(invoicingDetails.contactPersonName) && (
          <VerticalListItemWithPadding
            label={t('invoicingDetails.invoicingContactPerson')}
            data-testid={getListItemTestId('invoicing-contact-person')}
          >
            {invoicingDetails.contactPersonName}
          </VerticalListItemWithPadding>
        )}

        <InputField
          data-testid="order-level-reference-input-field"
          label={t('invoicingDetails.orderReference')}
          name="order-level-reference"
          value={form.values.orderLevelReference}
          onChange={setOrderLevelReference}
          size="m"
          maxLength={ORDER_LEVEL_REFERENCE_MAX_LENGTH_CHARS}
        />
      </VerticalContainerWithSmallGap>
    </Tile>
  );
};
