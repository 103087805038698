import { UserProfile } from '@models/usersManagement';
import { FC, useMemo } from 'react';
import { narrowCellContainerStyle, StyledTable, wideCellContainerStyle } from './styles';
import { getColumnsBaseConfiguration } from './config';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { CssValue, tableColumn, TableProps } from '@fortum/elemental-ui';
import { CellPlaceholder } from '@components/CellPlaceholder';
import { getEmptyData } from '@utils/dataPlaceholders';
import { translate } from '@utils/internationalization';
import { NO_DATA_BADGE } from '@utils/dataOperations';

interface UsersProfilesTableProps {
  usersProfiles: UserProfile[];
  isLoading: boolean;
  paginationHeight: CssValue;
  pageHeaderHeight: CssValue | undefined;
  onRowClick: (user: UserProfile) => void;
}

const emptyUsersProfiles: UserProfile[] = getEmptyData<UserProfile>(10).map((userProfile, index) => ({
  ...userProfile,
  uuid: index.toString(),
}));

interface CompaniesCellProps {
  companies: string[];
}

const CompaniesCell: FC<CompaniesCellProps> = ({ companies }) => {
  if (companies.length > 1) {
    return `${companies.length} companies`;
  }

  return companies.length === 0 ? NO_DATA_BADGE : companies[0];
};

export const UsersProfilesTable: FC<UsersProfilesTableProps> = ({
  usersProfiles,
  isLoading,
  onRowClick,
  paginationHeight,
  pageHeaderHeight = '250px',
}) => {
  const { i18n } = useTranslation<Namespace>('usersManagement');

  const columnsBaseConfig = useMemo(getColumnsBaseConfiguration, [i18n.language]);

  const placeholderColumns = useMemo(
    () => [
      tableColumn<UserProfile, string>(row => row.name)({
        ...columnsBaseConfig.name,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: narrowCellContainerStyle,
      }),
      tableColumn<UserProfile, string[]>(row => row.companies)({
        ...columnsBaseConfig.companies,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: wideCellContainerStyle,
      }),
      tableColumn<UserProfile, string>(row => row.email)({
        ...columnsBaseConfig.email,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: wideCellContainerStyle,
      }),
      tableColumn<UserProfile, string>(row => row.phone)({
        ...columnsBaseConfig.phone,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: narrowCellContainerStyle,
      }),
      tableColumn<UserProfile, string>(row => row.userType)({
        ...columnsBaseConfig.userType,
        renderCell: () => <CellPlaceholder />,
        cellContainerStyle: narrowCellContainerStyle,
      }),
    ],
    [columnsBaseConfig],
  );

  const columns = useMemo(
    () => [
      tableColumn<UserProfile, string>(row => `${row.name} ${row.lastName}`)({
        ...columnsBaseConfig.name,
        cellContainerStyle: narrowCellContainerStyle,
      }),
      tableColumn<UserProfile, string[]>(row => row.companies)({
        ...columnsBaseConfig.companies,
        cellContainerStyle: wideCellContainerStyle,
        renderCell: data => <CompaniesCell companies={data} />,
      }),
      tableColumn<UserProfile, string>(row => row.email)({
        ...columnsBaseConfig.email,
        cellContainerStyle: wideCellContainerStyle,
      }),
      tableColumn<UserProfile, string>(row => row.phone)({
        ...columnsBaseConfig.phone,
        cellContainerStyle: narrowCellContainerStyle,
      }),
      tableColumn<UserProfile, string>(row => translate(row.userType, 'usersManagement', 'userType'))({
        ...columnsBaseConfig.userType,
        cellContainerStyle: narrowCellContainerStyle,
      }),
    ],
    [columnsBaseConfig],
  );

  const stateDependantTableProps: Pick<TableProps<UserProfile>, 'rows' | 'columns'> = isLoading
    ? {
        rows: emptyUsersProfiles,
        columns: placeholderColumns,
      }
    : {
        rows: usersProfiles,
        columns,
      };

  return (
    <StyledTable
      data-testid="users-table"
      size="small"
      rowStyle="zebra"
      stickyHeader
      getRowKey={userProfile => userProfile.uuid}
      $paginationHeight={paginationHeight}
      $pageHeaderHeight={pageHeaderHeight}
      {...stateDependantTableProps}
      onRowClick={onRowClick}
    />
  );
};
