import { StyledDescriptionListItem, VerticalContainer } from '@components/styles';
import { FC } from 'react';
import { SectionHeader } from './SectionHeader';
import { IconProfileOutline } from '@fortum/elemental-ui';
import { DetailedUserProfile } from '@models/usersManagement';
import { DescriptionListContainer, MediumDescriptionListItem, StyledDescriptionList } from './styles';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { translate } from '@utils/internationalization';

interface MainSectionProps {
  user: DetailedUserProfile | undefined;
}

//TODO: Add placeholders
//TODO: Add styles for the status value
export const MainSection: FC<MainSectionProps> = ({ user }) => {
  const { t } = useTranslation<Namespace>('usersManagement');

  return (
    <VerticalContainer>
      <SectionHeader icon={<IconProfileOutline />} text={t('detailsPanel.mainSection.header')} size="m" />

      {user && (
        <DescriptionListContainer>
          <StyledDescriptionList size="condensed">
            <MediumDescriptionListItem label={t('detailsPanel.mainSection.name.label')} data-testid="main-section-name-item">
              {`${user.name} ${user.lastName}`}
            </MediumDescriptionListItem>

            <StyledDescriptionListItem label={t('detailsPanel.mainSection.email.label')} data-testid="main-section-email-item">
              {user.email}
            </StyledDescriptionListItem>

            <StyledDescriptionListItem label={t('detailsPanel.mainSection.phone.label')} data-testid="main-section-phone-item">
              {user.phone}
            </StyledDescriptionListItem>

            <StyledDescriptionListItem
              label={t('detailsPanel.mainSection.userType.label')}
              data-testid="main-section-user-type-item"
            >
              {translate(user.userType, 'usersManagement', 'userType')}
            </StyledDescriptionListItem>

            <StyledDescriptionListItem
              label={t('detailsPanel.mainSection.userRole.label')}
              data-testid="main-section-user-role-item"
            >
              {translate(user.userRole, 'usersManagement', 'userRole')}
            </StyledDescriptionListItem>

            <StyledDescriptionListItem
              label={t('detailsPanel.mainSection.language.label')}
              data-testid="main-section-language-item"
            >
              {user.language}
            </StyledDescriptionListItem>

            <StyledDescriptionListItem label={t('detailsPanel.mainSection.status.label')} data-testid="main-section-status-item">
              {user.status}
            </StyledDescriptionListItem>
          </StyledDescriptionList>
        </DescriptionListContainer>
      )}
    </VerticalContainer>
  );
};
