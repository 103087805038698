import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { displayedValue, hasValue } from '@utils/dataOperations';
import { getListItemTestId, getTileTestId } from '@utils/testsHelpers';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalContainerWithSmallGap, VerticalListItemWithPadding } from './styles';
import { translate } from '@utils/internationalization';
import { LocationPointDetails } from '@components/transportDetails';
import { InputField } from '@components/InputField';
import { Contacts } from './Contacts';
import { SingleOrderCreationContext } from '../SingleOrderCreationContextProvider';

export const TransportDetailsForm: FC = () => {
  const { t } = useTranslation<Namespace[]>(['wasteDetails', 'orderCreation']);

  const { form, contract, contactsDataHandler, setContactPersonId, setLocationPointAdditionalInfo } =
    useContext(SingleOrderCreationContext);

  const { transportDetails } = contract.serviceDetails;

  return (
    <Tile header={t('wasteDetails:transportDetails.details')} data-testid={getTileTestId('transport-details')}>
      <VerticalContainerWithSmallGap>
        <VerticalListItemWithPadding
          label={t('wasteDetails:transportDetails.transportType')}
          data-testid={getListItemTestId('transport-type')}
        >
          {translate(displayedValue(transportDetails.type), 'domain', 'transportType')}
        </VerticalListItemWithPadding>

        <Contacts
          contactsDataHandler={contactsDataHandler}
          selectedContactPersonId={form.values.contactPersonId}
          setSelectedContactPersonId={setContactPersonId}
        />

        <VerticalListItemWithPadding
          label={t('wasteDetails:transportDetails.loadingLocation')}
          data-testid={getListItemTestId('loading-location')}
        >
          <LocationPointDetails
            companyName={transportDetails.pickUpLocation.name}
            address={transportDetails.pickUpLocation.address}
            data-testid="loading-location"
          />
        </VerticalListItemWithPadding>

        <VerticalListItemWithPadding
          label={t('wasteDetails:transportDetails.receivingLocation')}
          data-testid={getListItemTestId('receiving-location')}
        >
          <LocationPointDetails
            companyName={transportDetails.deliveryLocation.name}
            address={transportDetails.deliveryLocation.address}
            data-testid="receiving-location"
          />
        </VerticalListItemWithPadding>

        {hasValue(transportDetails.instructionsForDriver) && (
          <VerticalListItemWithPadding
            label={t('transportDetails.instructionsForDriver')}
            data-testid={getListItemTestId('instructions-for-driver')}
          >
            {transportDetails.instructionsForDriver}
          </VerticalListItemWithPadding>
        )}

        <InputField
          data-testid="location-point-additional-info-input-field"
          name="location-point-additional-info"
          label={t('orderCreation:form.transportationAdditionalInfo.label')}
          value={form.values.locationPointAdditionalInfo}
          onChange={setLocationPointAdditionalInfo}
          size="m"
        />
      </VerticalContainerWithSmallGap>
    </Tile>
  );
};
