import { ComponentOrElement, ContentText, IconProps, renderComponentOrElement } from '@fortum/elemental-ui';
import { FC } from 'react';
import { SectionHeaderContainer, sectionIconStyles, smallHeaderTextStyles, mediumHeaderTextStyles } from './styles';
import { Stylable, Testable } from '@components/types';

interface SectionHeaderProps extends Stylable, Testable {
  size: 'm' | 's';
  text: string;
  icon: ComponentOrElement<IconProps>;
}

export const SectionHeader: FC<SectionHeaderProps> = ({ size, text, icon, className, 'data-testid': dataTestId }) => (
  <SectionHeaderContainer data-testid={dataTestId} className={className}>
    {renderComponentOrElement(icon, sectionIconStyles)}
    <ContentText {...(size === 's' ? smallHeaderTextStyles : mediumHeaderTextStyles)}>{text}</ContentText>
  </SectionHeaderContainer>
);
