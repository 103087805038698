import { isUnauthorizedError } from '@common/http';
import { StorageUtils, sessionKeys } from '@common/storage';
import { SuspenseLoader } from '@components/layout/styles';
import { queryClient } from '@config/queryClient';
import { paths } from '@config/routes';
import { getUserInfoQueryOptions } from '@data/hooks/useUserInfo';
import { useAuthProvider } from '@hooks/useAuthProvider';
import { GeneralError } from '@routes/errors/general/general-error';
import { FC, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Auth: FC = () => {
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();

  const { storeTokens, isAuthenticated, isLoading } = useAuthProvider();

  const processUserInfo = useCallback(async () => {
    if (error) return;

    try {
      const userData = await queryClient.fetchQuery(getUserInfoQueryOptions());

      if (!userData.agreementAccepted) {
        navigate(paths.termsAndConditions);
      } else if (userData.isAdmin && userData.isInternal) {
        navigate(paths.selectCustomer);
      } else if (userData.agreementAccepted) {
        const redirectPath = StorageUtils.popItem(sessionKeys.redirectUri) ?? paths.main;
        navigate(redirectPath);
      }
    } catch (e) {
      setError(e as Error);
    }
  }, [error, navigate]);

  const handleSuccessfulAuthentication = useCallback(async () => {
    await storeTokens();

    StorageUtils.putItem(sessionKeys.logMetric, 'true');

    processUserInfo();
  }, [processUserInfo, storeTokens]);

  useEffect(() => {
    if (isLoading) return;

    if (StorageUtils.hasItem(sessionKeys.accessToken)) {
      processUserInfo();

      return;
    }

    if (isAuthenticated) handleSuccessfulAuthentication();
  }, [isAuthenticated, isLoading]);

  if (error && isUnauthorizedError(error)) {
    navigate(paths.main); //Navigate to Root, to handle 401 error by displaying the error page wrapped with the header and the navigation bar
    return;
  }

  return <>{error ? <GeneralError /> : <SuspenseLoader />}</>;
};

export default Auth;
