import { orderTypesToEquipmentServiceTypesMap } from '@config/order';
import { CreationForms, OrdersCreationRequestBody } from 'src/types/ordersCreation';

export const mapCreationFormsToRequestBody = (creationForms: CreationForms, language: string): OrdersCreationRequestBody => {
  const orders = creationForms.flatMap(creationForm =>
    creationForm.forms.map(orderForm => ({
      uuid: orderForm.formIdentifier,
      contractNo: creationForm.contractNo,
      dataSource: creationForm.dataSource,
      equipmentEntryType: orderTypesToEquipmentServiceTypesMap[orderForm.values.orderType],
      orderLevelReference: orderForm.values.orderLevelReference || null,
      additionalComments: orderForm.values.comments || null,
      specifiedWasteDescription: orderForm.values.specifiedWasteDescription || null,
      locationPointAdditionalInfo: orderForm.values.locationPointAdditionalInfo || null,
      contactPersonId: orderForm.values.contactPersonId || null,
      estimatedWasteQuantity: orderForm.values.estimatedWasteQuantity || null,
      equipmentQuantity: orderForm.values.equipmentQuantity,
    })),
  );

  return {
    data: orders,
    language,
  };
};
