import { QueryKeys } from '@common/query';
import { fetchMapLocations } from '@data/api/home';
import { QueryOptions } from '@data/hooks/types';
import { MapLocations } from '@models/home';
import { UserAccess } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { getUserAccessInfo, isUserAccessOfInternalAdmin } from '@utils/user';

const mapLocationsQuery = (userAccess: UserAccess): QueryOptions<MapLocations> => ({
  queryKey: isUserAccessOfInternalAdmin(userAccess)
    ? [QueryKeys.locationsAdmin, userAccess.selectedBusinessPartners.map(bp => bp.id).join(',')]
    : [QueryKeys.locations],
  queryFn: () => fetchMapLocations(userAccess),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useMapLocations = () => useQuery<MapLocations, Error>(mapLocationsQuery(getUserAccessInfo()));
