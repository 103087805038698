import { FC } from 'react';
import { NonWrappingLinkLowerCased } from '@components/layout/styles';
import { Notification, UseNotification } from '@components/Notification';
import { colors, ContentText, LinkProps, spacing } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { useNavigate } from 'react-router-dom';
import { paths } from '@config/routes';

type OrderCreationFailedNotificationProps = Pick<UseNotification, 'closeNotification' | 'displayNotification'>;

const linkStyles: LinkProps = {
  ml: spacing.xxxxs,
  color: colors.snowWhite,
};

const notificationStyles = {
  width: '38rem',
};

const NotificationMessage: FC = () => {
  const { t } = useTranslation<Namespace>('errors');
  const navigate = useNavigate();

  return (
    <ContentText>
      {t('ordersCreation.requestFailed.notification.message.0')}
      <NonWrappingLinkLowerCased {...linkStyles} onClick={() => navigate(paths.contactUs)}>
        {t('ordersCreation.requestFailed.notification.message.1')}
      </NonWrappingLinkLowerCased>
    </ContentText>
  );
};

export const OrderCreationFailedNotification: FC<OrderCreationFailedNotificationProps> = ({
  closeNotification,
  displayNotification,
}) => (
  <Notification
    data-testid="creation-failed-notification"
    type="error"
    {...notificationStyles}
    content={<NotificationMessage />}
    opened={displayNotification === 'error'}
    onClose={closeNotification}
  />
);
