import { FC, useCallback, useRef, useState } from 'react';
import { UsersProfilesTableWithPagination } from './components/UsersProfilesTableWithPagination/UsersProfilesTableWithPagination';
import { PageHeader } from './components/PageHeader/PageHeader';
import { useUsersProfilesFilters } from '@data/hooks/useUsersProfilesFilters';
import { Container } from './components/styles';
import { useResizeObserver } from '@hooks/useResizeObserver';
import { UserProfilePanel } from './components/UserProfilePanel/UserProfilePanel';
import { UserProfile } from '@models/usersManagement';

//TODO: handle loading state
export const UsersManagement: FC = () => {
  const pageHeaderContainerRef = useRef<HTMLDivElement>(null);
  const [selectedCompanies, setSelectedCompanies] = useState<string[]>([]);
  const [search, setSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState<string | null>(null);

  const { data } = useUsersProfilesFilters({ companies: selectedCompanies, search });

  const { containerHeight: pageHeaderHeight } = useResizeObserver(pageHeaderContainerRef);

  const onTableRawClick = useCallback((user: UserProfile) => setSelectedUser(user.uuid), []);
  const resetUser = useCallback(() => setSelectedUser(null), []);

  return (
    <Container>
      <PageHeader
        ref={pageHeaderContainerRef}
        companies={data?.companies ?? []}
        selectedCompanies={selectedCompanies}
        setSelectedCompanies={setSelectedCompanies}
        searchText={search}
        setSearch={setSearch}
      />
      <UsersProfilesTableWithPagination
        search={search}
        companies={selectedCompanies}
        pageHeaderHeight={pageHeaderHeight}
        onRowClick={onTableRawClick}
      />

      <UserProfilePanel userId={selectedUser} open={!!selectedUser} onClose={resetUser} />
    </Container>
  );
};
