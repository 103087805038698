import { QueryKeys } from '@common/query';
import { sessionKeys, StorageUtils } from '@common/storage';
import { setUpConnection } from '@data/api/qlik';
import { useQuery } from '@tanstack/react-query';
import { QueryOptions } from './types';
import { shouldRetry } from '@utils/data';

const qlikSessionQuery = (): QueryOptions<boolean> => ({
  queryKey: [QueryKeys.qlikAuth],
  queryFn: setUpConnection,
  refetchOnWindowFocus: false,
  retry: shouldRetry,
  enabled: StorageUtils.hasItem(sessionKeys.idToken),
});

export const useQlikSession = () => useQuery<boolean, Error>(qlikSessionQuery());
