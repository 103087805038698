import { QueryOptions } from '@data/hooks/types';
import { QueryKeys } from '@common/query';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { DetailedUserProfile } from '@models/usersManagement';
import { fetchUserProfile } from '@data/api/usersManagement';

const userProfileQuery = (userId: string | null): QueryOptions<DetailedUserProfile> => ({
  queryKey: [QueryKeys.userProfile, userId],
  enabled: !!userId,
  queryFn: () => fetchUserProfile(userId!),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useUsersProfile = (userId: string | null) => useQuery<DetailedUserProfile, Error>(userProfileQuery(userId));
