import { RowProps, Row, Col, IconBin, IconDownloadFromCloud } from '@fortum/elemental-ui';
import { paths } from '@config/routes';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { FC } from 'react';
import { Tile } from './Tile';
import { co2ReportToggleOn } from '@utils/featureToggles';
import { columnStyles, firstRowColumnStyles, noCo2ColumnStyles, reportsColumnStyles } from './styles';
import { ReportsTile } from './ReportsTile';
import { OrdersTile } from './OrdersTile';

export interface QuickAccessSectionProps {
  marginBottom: RowProps['mb'];
}

export const QuickAccessSection: FC<QuickAccessSectionProps> = ({ marginBottom }) => {
  const { t } = useTranslation<Namespace[]>(['reports', 'home']);

  return (
    <>
      {co2ReportToggleOn ? (
        <>
          <Row>
            <Col {...firstRowColumnStyles}>
              <OrdersTile />
            </Col>

            <Col {...firstRowColumnStyles}>
              <Tile
                icon={<IconBin />}
                header={t('home:quickAccess.wasteServices.header')}
                description={t('home:quickAccess.wasteServices.desc')}
                links={[
                  {
                    id: 'services-forward-link',
                    description: t('home:quickAccess.wasteServices.linkDesc'),
                    path: paths.services,
                  },
                ]}
              />
            </Col>
          </Row>

          <Row mb={marginBottom}>
            <>
              <Col {...reportsColumnStyles}>
                <ReportsTile />
              </Col>

              <Col {...columnStyles}>
                <Tile
                  icon={<IconDownloadFromCloud />}
                  header={t('home:quickAccess.sustainability.header')}
                  description={t('home:quickAccess.sustainability.desc')}
                  links={[
                    {
                      id: 'co2-report-forward-link',
                      description: t('reports:co2.navigation'),
                      path: paths.co2Report,
                    },
                  ]}
                />
              </Col>
            </>
          </Row>
        </>
      ) : (
        <>
          <Row mb={marginBottom}>
            <Col {...noCo2ColumnStyles}>
              <OrdersTile linksDirection="vertical" />
            </Col>

            <Col {...noCo2ColumnStyles}>
              <Tile
                icon={<IconBin />}
                header={t('home:quickAccess.wasteServices.header')}
                description={t('home:quickAccess.wasteServices.desc')}
                links={[
                  {
                    id: 'services-forward-link',
                    description: t('home:quickAccess.wasteServices.linkDesc'),
                    path: paths.services,
                  },
                ]}
                linksDirection="vertical"
              />
            </Col>

            <Col {...noCo2ColumnStyles}>
              <ReportsTile linksDirection="vertical" />
            </Col>
          </Row>
        </>
      )}
    </>
  );
};
