import { SideDrawer } from '@components/SideDrawer';
import { useUsersProfile } from '@data/hooks/useUserProfile';
import { IconButton, IconCross } from '@fortum/elemental-ui';
import { FC } from 'react';
import { Container, DRAWER_WIDTH, Divider, closeButtonStyles } from './styles';
import { MainSection } from './MainSection';

interface UserProfilePanelProps {
  userId: string | null;
  open: boolean;
  onClose: () => void;
}

export const UserProfilePanel: FC<UserProfilePanelProps> = ({ userId, open, onClose }) => {
  const { data: user } = useUsersProfile(userId);

  return (
    <SideDrawer open={open} onClickOutside={onClose} width={DRAWER_WIDTH}>
      <Container>
        <IconButton {...closeButtonStyles} icon={<IconCross size={24} />} onClick={onClose} />

        <MainSection user={user} />

        <Divider />
      </Container>
    </SideDrawer>
  );
};
