import { Tile } from '@components/Tile';
import { Namespace } from '@config/i18n';
import { displayedValue } from '@utils/dataOperations';
import { translateIfExist } from '@utils/internationalization';
import { getTileTestId, getListItemTestId } from '@utils/testsHelpers';
import { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { VerticalContainerWithSmallGap, VerticalListItemWithPadding } from './styles';
import { SingleOrderCreationContext } from '../SingleOrderCreationContextProvider';

export const EquipmentDetails: FC = () => {
  const { t } = useTranslation<Namespace>('wasteDetails');

  const { contract } = useContext(SingleOrderCreationContext);

  const equipmentOwnership = translateIfExist(contract.serviceDetails.equipmentDetails.type, 'domain', 'equipmentGroup');

  return (
    <Tile header={t('equipmentDetails')} data-testid={getTileTestId('equipment-details')}>
      <VerticalContainerWithSmallGap>
        <VerticalListItemWithPadding label={t('equipmentType')} data-testid={getListItemTestId('equipment-type')}>
          {displayedValue(contract.serviceDetails.equipmentDetails.description)}
        </VerticalListItemWithPadding>

        {equipmentOwnership && (
          <VerticalListItemWithPadding label={t('equipmentOwnership')} data-testid={getListItemTestId('equipment-ownership')}>
            {equipmentOwnership}
          </VerticalListItemWithPadding>
        )}
      </VerticalContainerWithSmallGap>
    </Tile>
  );
};
