import { FC } from 'react';
import { gridStyles, columnStyles, VerticalContainerWithBigGap } from './styles';
import { Grid, Row, Col } from '@fortum/elemental-ui';
import { EquipmentDetails } from './EquipmentDetails';
import { TransportDetailsForm } from './TransportDetailsForm';
import { InvoicingDetailsForm } from './InvoicingDetailsForm';
import { Testable } from '@components/types';

export const DetailsForm: FC<Testable> = ({ 'data-testid': dataTestId }) => (
  <Grid {...gridStyles} data-testid={dataTestId}>
    <Row>
      <Col {...columnStyles}>
        <TransportDetailsForm />
      </Col>

      <Col {...columnStyles}>
        <VerticalContainerWithBigGap>
          <EquipmentDetails />
          <InvoicingDetailsForm />
        </VerticalContainerWithBigGap>
      </Col>
    </Row>
  </Grid>
);
