import { Contact } from '@models/contact';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { getContactDescription } from '@utils/contact';
import { SelectItem } from '@fortum/elemental-ui';
import { Select } from '@components/Select';
import { filterSelectItems } from '@utils/dataOperations';
import { UseQueryResult } from '@tanstack/react-query';

interface ContactsProps {
  contactsDataHandler: UseQueryResult<Contact[], Error>;
  selectedContactPersonId: string;
  setSelectedContactPersonId: (id: string) => void;
}

export const Contacts: FC<ContactsProps> = ({ contactsDataHandler, setSelectedContactPersonId, selectedContactPersonId }) => {
  const { t, i18n } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'errors']);
  const { data: contacts, isLoading, isError } = contactsDataHandler;

  const disabled = useMemo(
    () => !contacts || isLoading || isError || (contacts.length === 1 && contacts[0].code === selectedContactPersonId),
    [contacts, selectedContactPersonId, isLoading, isError],
  );

  const displayValue = useMemo(() => {
    const selectedContact = contacts?.find(contact => contact.code === selectedContactPersonId);

    return selectedContact ? getContactDescription(selectedContact) : t('orderCreation:selectContactPerson');
  }, [selectedContactPersonId, contacts, i18n.language]);

  const items = useMemo<SelectItem<string>[]>(
    () => (contacts ? contacts.map(contact => ({ value: contact.code, name: contact.name })) : []),
    [contacts],
  );

  return (
    <Select
      label={t('wasteDetails:transportDetails.contactDetails')}
      name="contacts"
      size="m"
      items={items}
      selected={selectedContactPersonId}
      onSelectedItemChange={setSelectedContactPersonId}
      displayValue={displayValue}
      disabled={disabled}
      filterItems={filterSelectItems}
      error={isError}
      errorMessage={t('errors:generalError.failedToFetch')}
    />
  );
};
