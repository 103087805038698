import { Namespace } from '@config/i18n';
import { useUserInfo } from '@data/hooks/useUserInfo';
import { Box, ContentText, RowKey, SortOrder, TableColumnProps, fontSizes, fontWeights, spacing } from '@fortum/elemental-ui';
import { BusinessPartner } from '@models/user';
import { getSelectedBusinessPartners } from '@utils/user';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OrganisationsSelectorNavigation } from './OrganisationsSelectorNavigation';
import { RightCenteredLoader, StyledTable } from './style';

export const BusinessPartnersTable = () => {
  const { data: user, isLoading, fetchStatus } = useUserInfo();
  const { t, i18n } = useTranslation<Namespace>('user');
  const [sortConfig] = useState<{ keys: (keyof BusinessPartner)[]; order: SortOrder[] }>({
    keys: ['name'],
    order: ['asc'],
  });
  const adminBusinessPartners = useMemo(() => getSelectedBusinessPartners(), []);

  const columns = useMemo<TableColumnProps<BusinessPartner, RowKey | RowKey[]>[]>(
    () => [
      {
        getCellData: (row: BusinessPartner) => row.name,
        key: 'name',
        name: t('partnersTable.name'),
        sortable: true,
      },
      {
        getCellData: (row: BusinessPartner) => row.id,
        key: 'id',
        name: t('partnersTable.vatNumber'),
        sortable: true,
      },
    ],
    [i18n.language],
  );

  return (
    <Box>
      {isLoading || fetchStatus !== 'idle' ? (
        <RightCenteredLoader />
      ) : (
        user &&
        (user.isAdmin && user.isInternal ? (
          <>
            <ContentText fontSize={fontSizes.m} fontWeight={fontWeights.medium} marginBottom={spacing.xxxs}>
              {t('partnersTable.tableTitle')} ({adminBusinessPartners.length})
            </ContentText>
            <OrganisationsSelectorNavigation />
            <StyledTable
              stickyHeader
              getRowKey={row => row.id}
              columns={columns}
              rows={adminBusinessPartners}
              sortBy={sortConfig.keys}
              sortOrder={sortConfig.order}
              rowStyle="zebra"
              variant="normal"
              size="large"
            />
          </>
        ) : (
          <>
            <ContentText fontSize={fontSizes.m} fontWeight={fontWeights.medium} marginBottom={spacing.xxxs}>
              {t('partnersTable.tableTitle')} ({user.businessPartners.length})
            </ContentText>
            <StyledTable
              stickyHeader
              getRowKey={row => row.id}
              columns={columns}
              rows={user.businessPartners}
              sortBy={sortConfig.keys}
              sortOrder={sortConfig.order}
              rowStyle="zebra"
              variant="normal"
              size="large"
            />
          </>
        ))
      )}
    </Box>
  );
};
