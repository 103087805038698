import { SupportedLanguage, fallbackLang } from '@common/languages';
import { QlikReportConfig, ReportDefaultSelections, ReportIds } from './types';
import { qlikAppConfig } from './connection';
import { getUserAccessInfo } from '@utils/user';

//TODO: Allow passing configs through env variables, same as in the CO2 report

// TODO: uncomment when Norvegian translations will be finished. Add Norwegian to CompanyNameSelectionFieldName
const CostsReports: ReportIds = {
  en: process.env.REACT_APP_COST_REPORT_ID_EN ?? '',
  fi: process.env.REACT_APP_COST_REPORT_ID_FI ?? '',
  sv: process.env.REACT_APP_COST_REPORT_ID_SV ?? '',
  // nb: process.env.REACT_APP_COST_REPORT_ID_NO ?? '',
};

export const CostReportDefaultSelections: ReportDefaultSelections = {
  en: [
    {
      fieldName: 'Company Information',
      values: ['Company name', 'Company site city'],
    },
    {
      fieldName: 'Execution Year',
      values: [`${new Date().getFullYear()}`],
    },
    {
      fieldName: 'Measures',
      values: ['Costs', 'Credit for materials', 'Total costs'],
    },
  ],
  fi: [
    {
      fieldName: 'Yrityksen tiedot',
      values: ['Yrityksen nimi', 'Yrityksen paikkakunta'],
    },
    {
      fieldName: 'Kustannukset ja määrät',
      values: ['Kustannukset', 'Materiaalihyvitykset', 'Kokonaiskustannukset'],
    },
    { fieldName: 'Tapahtumavuosi', values: [`${new Date().getFullYear()}`] },
  ],
  sv: [
    {
      fieldName: 'Fakturadetaljer',
      values: ['Tjänst'],
    },
    {
      fieldName: 'Kostnader & mängder',
      values: ['Total kostnad', 'Mängd', 'Enhet'],
    },
    { fieldName: 'År', values: [`${new Date().getFullYear()}`] },
  ],
  /* nb: [
    {
      fieldName: 'Faktura detaljer',
      values: ['Tjeneste'],
    },
    {
      fieldName: 'Kostnader & mengder',
      values: ['Totale kostnader', 'Mengde'],
    },
    { fieldName: 'År', values: [`${new Date().getFullYear()}`] },
  ],
  */
};

const CompanyNameSelectionFieldName: { [key in SupportedLanguage]: string } = {
  en: 'Company name',
  fi: 'Yrityksen nimi',
  sv: 'Företagsnamn',
};

export interface CostReportObjects {
  filters: {
    executionMonth: string;
    executionYear: string;
    executionQuarter: string;
    invoiceMonth: string;
    invoiceQuarter: string;
    invoiceYear: string;
  };
  visualization: {
    table: string;
    dimensions: {
      company: string | undefined;
      customerHierarchy: string | undefined;
      wasteDetails: string | undefined;
      orderDetails: string | undefined;
      invoiceDetails: string | undefined;
      measures: string | undefined;
    };
  };
}

const ReportCommonObjectsIds: CostReportObjects = {
  filters: {
    executionMonth: '7cde085c-bd5b-4715-844f-09500dc92a8e',
    executionQuarter: 'XLjm',
    executionYear: 'e8cfbeb6-d05f-450e-bbde-d6d74189a694',
    invoiceMonth: '46d3048d-fea3-4429-b2a0-dd318fd1b70e',
    invoiceQuarter: 'xGBPGfH',
    invoiceYear: '62d0b882-9058-4e51-8547-2932487d9362',
  },
  visualization: {
    table: 'a66b5f3a-9fc6-4f67-bfff-8572fac97d80',
    dimensions: {
      company: 'e360326c-8221-4f5e-9f15-c9e33ec55d69',
      customerHierarchy: 'tzFKu',
      wasteDetails: '2c33a534-3dba-4a44-8eab-fe6d16dc0427',
      orderDetails: 'a6d05850-7c70-48d2-a91a-88b462267535',
      invoiceDetails: 'b5f4f68d-fbe2-4854-832f-5a32093b2771',
      measures: 'bxQEBF',
    },
  },
};

const ReportsObjectsIdsPerLang: { [key in SupportedLanguage]: CostReportObjects } = {
  en: { ...ReportCommonObjectsIds },
  fi: { ...ReportCommonObjectsIds },
  sv: {
    ...ReportCommonObjectsIds,
    visualization: {
      ...ReportCommonObjectsIds.visualization,
      dimensions: {
        ...ReportCommonObjectsIds.visualization.dimensions,
        customerHierarchy: undefined,
      },
    },
  },
  /* nb: {
    ...ReportCommonObjectsIds,
    visualization: {
      ...ReportCommonObjectsIds.visualization,
      dimensions: {
        ...ReportCommonObjectsIds.visualization.dimensions,
        customerHierarchy: undefined,
      },
    },
  },
  */
};

export const getCostReportConfig = (lang: SupportedLanguage): QlikReportConfig<CostReportObjects> => {
  const reportLanguage: SupportedLanguage = CostsReports[lang] ? lang : fallbackLang;

  const staticConfig = {
    qlikAppConfig: {
      ...qlikAppConfig,
      appId: CostsReports[reportLanguage],
    },
    defaultSelections: CostReportDefaultSelections[reportLanguage],
    objectIds: ReportsObjectsIdsPerLang[reportLanguage],
  };

  const userAccessInfo = getUserAccessInfo();

  if (!(userAccessInfo.isInternal && userAccessInfo.isAdmin)) {
    return staticConfig;
  }

  return {
    ...staticConfig,
    mandatorySelections: [
      {
        fieldName: CompanyNameSelectionFieldName[reportLanguage],
        values: userAccessInfo.selectedBusinessPartners.map(bp => bp.name),
      },
    ],
  };
};
