import { QueryKeys } from '@common/query';
import type { StatisticLoggerUserRole } from '@common/statistics';
import { sessionKeys, StorageUtils } from '@common/storage';
import { queryClient } from '@config/queryClient';
import type { BusinessPartner, InternalAdminAccess, User, UserAccess } from '@models/user';

export const SELECTED_BUSINESS_PARTNERS_LIMIT = 10;

export const bpsSelectionMissing = (userAccess: UserAccess | undefined) => {
  if (userAccess?.isInternal && userAccess?.isAdmin && userAccess?.selectedBusinessPartners.length === 0) {
    return true;
  }

  return false;
};

// TODO: consider this moving into another util bundle, but as for now it's mostly used with users operations, thus it could live here
export const getSelectedBusinessPartners = (): BusinessPartner[] =>
  StorageUtils.getObject<BusinessPartner[]>(sessionKeys.selectedBusinessPartners) || [];

export const isUserInternalAdmin = (user: User | undefined) => !!user && user.isInternal && user.isAdmin;

export const isUserAccessOfInternalAdmin = (userAccess: UserAccess | undefined): userAccess is InternalAdminAccess =>
  !!userAccess && userAccess.isInternal && userAccess.isAdmin;

export const getUserAccessInfo = (): UserAccess => {
  const user = queryClient.getQueryData<User>([QueryKeys.userInfo]);

  if (!user) throw new Error('User not initialized');

  if (isUserInternalAdmin(user)) {
    return {
      isInternal: true,
      isAdmin: true,
      selectedBusinessPartners: getSelectedBusinessPartners(),
    };
  }

  return { isInternal: user.isInternal, isAdmin: false };
};

export const getUserRoleForStatistics = (user: User): StatisticLoggerUserRole => {
  if (!user.isInternal) return 'external';

  return user.isAdmin ? 'internalAdmin' : 'internal';
};

export const bpsSelectionItemDisabled = (selectedBps: BusinessPartner[], itemId: string, itemName: string) =>
  selectedBps.length >= SELECTED_BUSINESS_PARTNERS_LIMIT &&
  !selectedBps.some(selectedBP => selectedBP.id === itemId && selectedBP.name === itemName);

export const getUserName = (user: User | undefined) => (user ? [user.name, user.lastname].filter(Boolean).join(' ') : '');
