import { SupportedLanguage, fallbackLang } from '@common/languages';
import { QlikReportConfig, ReportDefaultSelections, ReportIds } from '../types';
import { qlikAppConfig } from '../connection';
import { getUserAccessInfo } from '@utils/user';
import { defaultCo2ReportDefaultSelectionsConfig, defaultCompanyNameSelectionFieldName } from './defaults';

//TODO: consider keeping whole report configuration in one object: object ids, app ids, default selections, company name selection field name

// TODO: uncomment when Norvegian translations will be finished. Add Norwegian to CompanyNameSelectionFieldName
const CO2Reports: ReportIds = {
  en: process.env.REACT_APP_CO2_REPORT_ID_EN ?? '',
  fi: process.env.REACT_APP_CO2_REPORT_ID_FI ?? '',
  sv: process.env.REACT_APP_CO2_REPORT_ID_SV ?? '',
  // nb: process.env.REACT_APP_CO2_REPORT_ID_NO ?? '',
};

const cO2ReportDefaultSelections: ReportDefaultSelections = process.env.REACT_APP_CO2_REPORT_DEFAULT_VALUES
  ? JSON.parse(process.env.REACT_APP_CO2_REPORT_DEFAULT_VALUES)
  : defaultCo2ReportDefaultSelectionsConfig;

const companyNameSelectionFieldName: { [key in SupportedLanguage]: string } = process.env
  .REACT_APP_CO2_REPORT_COMPANY_NAME_SELECTION_FIELD_NAME
  ? JSON.parse(process.env.REACT_APP_CO2_REPORT_COMPANY_NAME_SELECTION_FIELD_NAME)
  : defaultCompanyNameSelectionFieldName;

//TODO: allow passing object ids through GH variables.
// Since object ids almost always should be same for all languages, make only EN required
export interface Co2ReportObjects {
  filters: {
    companyName: string;
    companySiteCity: string;
    executionYear: string;
    executionMonth: string;
    hazardousNonHazardous: string;
    transportEmission: string;
  };
  kpis: {
    co2eEmissions: string;
    avoidedCo2eEmissions: string;
    netCo2eEmissions: string;
    largestCo2eWasteProducing: string;
  };
  charts: {
    totalWasteAmount: string;
    totalEmissions: string;
    top3NetCo2eProducingWaste: string;
    emissionsByLogisticsAndTreatment: string;
    co2eEmissions: string;
    co2eEmissionsAvoided: string;
    emissionsByWasteTreatmentMethodCo2: string;
  };
  visualization: {
    table: string;
    dimensions: {
      companyInfo: string;
      wasteDetails: string;
    };
  };
}

const ReportCommonObjectsIds: Co2ReportObjects = {
  filters: {
    companyName: 'phTPh',
    companySiteCity: 'FRgkhc',
    executionYear: 'aUvjPn',
    executionMonth: 'FkdPDw',
    hazardousNonHazardous: 'XsXGJFB',
    transportEmission: 'EUmJS',
  },
  kpis: {
    co2eEmissions: 'jMmq',
    avoidedCo2eEmissions: 'WxTrGXA',
    netCo2eEmissions: 'NrPU',
    largestCo2eWasteProducing: 'MphqaU',
  },
  charts: {
    totalWasteAmount: 'cCyxV',
    totalEmissions: 'SpecVbP',
    top3NetCo2eProducingWaste: 'HRafe',
    emissionsByLogisticsAndTreatment: 'qkJLcm',
    co2eEmissions: 'VRjptC',
    co2eEmissionsAvoided: 'GxPMysJ',
    emissionsByWasteTreatmentMethodCo2: 'jJsJe',
  },
  visualization: {
    table: 'c54daf79-1bbf-443c-8fd2-960698a8d2c2',
    dimensions: {
      companyInfo: '893fd51f-b891-4426-9545-4b2d8b7aa229',
      wasteDetails: '6491e19e-1685-40f5-abe9-44b75ef26b6d',
    },
  },
};

const ReportsObjectsIdsPerLang: { [key in SupportedLanguage]: Co2ReportObjects } = {
  en: { ...ReportCommonObjectsIds },
  fi: { ...ReportCommonObjectsIds },
  sv: { ...ReportCommonObjectsIds },
  /* nb: {
    ...ReportCommonObjectsIds,
    visualization: {
      ...ReportCommonObjectsIds.visualization,
      dimensions: {
        ...ReportCommonObjectsIds.visualization.dimensions,
        customerHierarchy: undefined,
      },
    },
  },
  */
};

export const getCo2ReportConfig = (lang: SupportedLanguage): QlikReportConfig<Co2ReportObjects> => {
  const reportLanguage: SupportedLanguage = CO2Reports[lang] ? lang : fallbackLang;

  const staticConfig = {
    qlikAppConfig: {
      ...qlikAppConfig,
      appId: CO2Reports[reportLanguage],
    },
    defaultSelections: cO2ReportDefaultSelections[reportLanguage],
    objectIds: ReportsObjectsIdsPerLang[reportLanguage],
  };

  const userAccessInfo = getUserAccessInfo();

  if (!(userAccessInfo.isInternal && userAccessInfo.isAdmin)) {
    return staticConfig;
  }

  return {
    ...staticConfig,
    mandatorySelections: [
      {
        fieldName: companyNameSelectionFieldName[reportLanguage],
        values: userAccessInfo.selectedBusinessPartners.map(bp => bp.name),
      },
    ],
  };
};
