import { urls } from '@data/api/config';
import { ChartDataResponse } from '@models/home';
import { Service, Services } from '@models/service';
import { UserAccess } from '@models/user';
import { getData, postData } from '@utils/data';
import { isUserAccessOfInternalAdmin } from '@utils/user';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';
import {
  ContractFiltersKeys,
  ContractFiltersRequestBody,
  ContractMetaFilters,
  ContractMetaFiltersRequestBody,
} from 'src/types/services';

export const fetchServices = async (
  selectedFiltersKeys: ContractFiltersKeys,
  userAccess: UserAccess,
  language: string,
): Promise<Service> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

  if (!urls.services || !urls.servicesAdmin) throw Error('Services url not found');

  const body: ContractFiltersRequestBody = {
    language,
    filters: {
      wastes: selectedFiltersKeys.wastes.length > 0 ? selectedFiltersKeys.wastes : null,
      equipments: selectedFiltersKeys.equipments.length > 0 ? selectedFiltersKeys.equipments : null,
      search: selectedFiltersKeys.search !== '' ? selectedFiltersKeys.search : null,
    },
  };

  if (isUserAccessOfInternalAdmin(userAccess)) {
    return postData(
      urls.servicesAdmin,
      JSON.stringify({
        businessPartnersIds: userAccess.selectedBusinessPartners.map(bp => bp.id),
        ...body,
      }),
      contentTypeHeader,
    );
  }

  return postData(`${urls.services}`, JSON.stringify(body), contentTypeHeader);
};

export const fetchServiceFilters = async (
  userAccess: UserAccess,
  language: string,
  additionalFilteringForOrdersCreation = false,
  filtersKeys?: ContractFiltersKeys,
): Promise<ContractMetaFilters> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);
  if (!urls.servicesFilters) throw Error('Services filters url not found');

  const body: ContractMetaFiltersRequestBody = {
    language,
    metaFilters: {
      equipmentsByWaste: filtersKeys?.wastes || null,
      wastesByEquipment: filtersKeys?.equipments || null,
      filterForOrderCreation: additionalFilteringForOrdersCreation || undefined,
    },
  };

  if (isUserAccessOfInternalAdmin(userAccess)) {
    return postData(
      `${urls.servicesFiltersAdmin}`,
      JSON.stringify({
        businessPartnersIds: userAccess.selectedBusinessPartners.map(bp => bp.id),
        ...body,
      }),
      contentTypeHeader,
    );
  } else {
    return postData(`${urls.servicesFilters}`, JSON.stringify(body), contentTypeHeader);
  }
};

export const fetchServiceDetails = async (
  id: string,
  selectedFiltersKeys: ContractFiltersKeys,
  language: string,
): Promise<Services> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);
  if (!urls.servicesDetails) throw Error('Service details url not found');

  const body: ContractFiltersRequestBody = {
    language,
    filters: {
      wastes: selectedFiltersKeys.wastes.length > 0 ? selectedFiltersKeys.wastes : null,
      equipments: selectedFiltersKeys.equipments.length > 0 ? selectedFiltersKeys.equipments : null,
      search: selectedFiltersKeys.search !== '' ? selectedFiltersKeys.search : null,
    },
  };

  return postData(`${urls.servicesDetails}/${id}`, JSON.stringify(body), contentTypeHeader);
};

export const fetchHazardSummaryInWasteServices = async (userAccess: UserAccess): Promise<ChartDataResponse> => {
  const contentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

  if (isUserAccessOfInternalAdmin(userAccess)) {
    if (!urls.hazardousInWasteServicesSummaryForAdmin) {
      throw Error('Hazardous in waste services summary for admins URL not found');
    }

    const businessPartnerCodes = userAccess.selectedBusinessPartners.map(bp => bp.id);

    return postData<ChartDataResponse>(
      urls.hazardousInWasteServicesSummaryForAdmin,
      JSON.stringify({
        businessPartnerCodes,
      }),
      contentTypeHeader,
    );
  }

  if (!urls.hazardousInWasteServicesSummary) {
    throw Error('Hazardous in waste services summary URL not found');
  }

  return getData<ChartDataResponse>(urls.hazardousInWasteServicesSummary);
};
