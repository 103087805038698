import { SupportedLanguage } from '@common/languages';
import { ReportDefaultSelections } from '../types';

export const defaultCo2ReportDefaultSelectionsConfig: ReportDefaultSelections = {
  en: [
    {
      fieldName: 'Company information',
      values: ['Company name', 'Company site city'],
    },
  ],
  fi: [
    {
      fieldName: 'Yrityksen tiedot',
      values: ['Yrityksen nimi', 'Yrityksen paikkakunta'],
    },
  ],
  sv: [
    {
      fieldName: 'Företagsinformation',
      values: ['Ort', 'Anläggningsnamn'],
    },
  ],
};

export const defaultCompanyNameSelectionFieldName: {
  [key in SupportedLanguage]: string;
} = {
  en: 'Company name',
  fi: 'Yrityksen nimi',
  sv: 'Företagsnamn',
};
