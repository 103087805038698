import { HorizontalContainer, StyledDescriptionListItem, VerticalContainer } from '@components/styles';
import {
  colors,
  ContentTextProps,
  DescriptionList,
  fontSizes,
  fontWeights,
  IconButtonProps,
  IconProps,
  px2rem,
  spacing,
} from '@fortum/elemental-ui';
import styled from 'styled-components';

export const DRAWER_WIDTH = '490px';

export const Container = styled(VerticalContainer)`
  padding: ${spacing.xs} ${spacing.s};
  position: relative;
`;

export const HeaderContainer = styled(HorizontalContainer)`
  justify-content: space-between;
  align-items: center;
`;

export const SectionHeaderContainer = styled(HorizontalContainer)`
  gap: ${spacing.xxxs};
`;

export const closeButtonStyles: Omit<IconButtonProps, 'icon'> = {
  status: 'plain',
  color: colors.inkGrey,
  top: '19px',
  right: spacing.xs,
  position: 'absolute',
};

export const sectionIconStyles: IconProps = {
  color: colors.oceanGreen,
  size: 24,
};

const headerTextBasicStyles: ContentTextProps = {
  fontWeight: fontWeights.medium,
};

export const smallHeaderTextStyles: ContentTextProps = {
  ...headerTextBasicStyles,
  fontSize: fontSizes.m,
};

export const mediumHeaderTextStyles: ContentTextProps = {
  ...headerTextBasicStyles,
  fontSize: fontSizes.l,
};

export const MediumDescriptionListItem = styled(StyledDescriptionListItem)`
  dd {
    font-size: ${fontSizes.m};
    font-weight: ${fontWeights.medium};
  }
`;

export const StyledDescriptionList = styled(DescriptionList)`
  div > dd,
  div > dt {
    padding-top: ${spacing.xxxxs} !important;
    padding-bottom: ${spacing.xxxxs} !important;
  }
`;

export const DescriptionListContainer = styled.div`
  padding: 20px ${spacing.s};
`;

export const Divider = styled.div`
  border-bottom: ${px2rem(2)} solid ${colors.cloudGrey};
`;
