import { useCallback, useContext, useMemo } from 'react';
import { ContractsFiltersContext } from '@contexts/ContractsFiltersContext';
import { displayMultiselectAppliedValues, moveSelectedToStart } from '@utils/dataOperations';
import { shouldFiltersBeDisabled } from '@utils/servicesFilters';
import { ContractsFiltersDisability } from 'src/types/services';
import { useDebounceWithTemporaryValue, useContractsSitesMultiselect } from '@hooks/index';
import { APPLY_ADDITIONAL_FILTERING_TO_SERVICES_SELECTOR_DATA } from '@routes/ordersCreation/config';

export const useServicesSelector = () => {
  const {
    handleSearchChange,
    handleWasteTypesChange,
    handleEquipmentTypesChange,
    queryResult: filtersQueryResult,
    search,
    selectedEquipmentTypes,
    selectedWasteTypes,
    filtersKeysWithSearch: filtersKeys,
    filtersSelectItems,
    debouncedFiltersKeysWithSearch,
    lastDataReloadCause,
    resetAll: resetAllFilters,
  } = useContext(ContractsFiltersContext);

  const {
    areSitesLoading,
    isSitesError,
    sitesItems,
    selectedSitesDisplayValue,
    selectedSites,
    selectedLocationCodes,
    shouldBeDisabled,
    setSelectedSites,
    sortedSitesItems,
  } = useContractsSitesMultiselect(debouncedFiltersKeysWithSearch, APPLY_ADDITIONAL_FILTERING_TO_SERVICES_SELECTOR_DATA);

  const { changeTemporaryValue: changeTemporarySearch, temporaryValue: temporarySearch } = useDebounceWithTemporaryValue(
    search,
    handleSearchChange,
  );

  const sortedWasteItems = useMemo(
    () => moveSelectedToStart(filtersSelectItems.wasteTypes, filtersKeys.wastes),
    [filtersKeys.wastes, filtersSelectItems.wasteTypes],
  );

  const sortedEquipmentItems = useMemo(
    () => moveSelectedToStart(filtersSelectItems.equipmentTypes, filtersKeys.equipments),
    [filtersSelectItems.equipmentTypes, filtersKeys.equipments],
  );

  const selectedWasteDisplayValue = useMemo<string | undefined>(() => {
    if (selectedWasteTypes.length > 0) {
      return displayMultiselectAppliedValues(selectedWasteTypes.map(wasteType => wasteType.value));
    }
  }, [selectedWasteTypes]);

  const selectedEquipmentDisplayValue = useMemo<string | undefined>(() => {
    if (selectedEquipmentTypes.length > 0) {
      return displayMultiselectAppliedValues(selectedEquipmentTypes.map(equipmentType => equipmentType.value));
    }
  }, [selectedEquipmentTypes]);

  const filtersDisabled = useMemo<ContractsFiltersDisability>(
    () =>
      shouldFiltersBeDisabled(filtersSelectItems, filtersQueryResult.isLoading, filtersQueryResult.isError, lastDataReloadCause),
    [filtersQueryResult.isLoading, filtersQueryResult.isError, filtersSelectItems, lastDataReloadCause],
  );

  const clearAllSelections = useCallback(() => {
    resetAllFilters();
    setSelectedSites([]);
  }, [resetAllFilters, setSelectedSites]);

  return {
    filters: {
      debouncedFiltersKeysWithSearch,
      filtersKeys,
      queryResult: filtersQueryResult,
      temporarySearch,
      shouldBeDisabled: filtersDisabled,
      selectedEquipmentDisplayValue,
      selectedWasteDisplayValue,
      sortedEquipmentItems,
      sortedWasteItems,
      changeTemporarySearch,
      handleWasteTypesChange,
      handleEquipmentTypesChange,
    },
    sites: {
      selectedSitesDisplayValue,
      sitesItems,
      selectedLocationCodes,
      selectedSites,
      shouldBeDisabled,
      areSitesLoading,
      isSitesError,
      sortedSitesItems,
      setSelectedSites,
    },
    clearAllSelections,
  };
};
