import { Namespace } from '@config/i18n';
import { paths } from '@config/routes';
import {
  Col,
  ContentText,
  ForwardLink,
  IconConstructionWorker,
  IconCustomerService,
  IconLinkedin,
  Row,
  RowProps,
} from '@fortum/elemental-ui';
import { getEnvProperty } from '@utils/general';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  columnStyles,
  contactUsDescriptionTextStyles,
  ContactUsHeader,
  contactUsHeaderTextStyles,
  ContactUsTileContainer,
  CustomerServiceIconRoundedContainer,
  descriptionTextStyles,
  ETrainingContainer,
  forwardLinkStyles,
  iconConstructionWorkerStyles,
  iconCustomerServiceStyles,
  iconLinkedinStyles,
  LeftColumnContainer,
  leftColumnStyles,
  NonWrappingForwardLink,
  positionedForwardLinkStyles,
  SocialMediaContainer,
} from './styles';

export const InfoSection: FC<RowProps> = props => {
  const navigate = useNavigate();
  const { t } = useTranslation<Namespace[]>(['home', 'mainLayout']);

  const openLinkedIn = useCallback(() => {
    const linkedInUrl = getEnvProperty('REACT_APP_LINKEDIN_URL');

    if (linkedInUrl) window.open(linkedInUrl, '_blank');
  }, []);

  const openETraining = useCallback(() => {
    const eTrainingUrl = getEnvProperty('REACT_APP_ETRAINING_URL');

    if (eTrainingUrl) window.open(eTrainingUrl, '_blank');
  }, []);

  return (
    <Row {...props}>
      <Col {...leftColumnStyles}>
        <LeftColumnContainer>
          <ETrainingContainer>
            <IconConstructionWorker {...iconConstructionWorkerStyles} />

            <ContentText {...descriptionTextStyles}>{t('info.etraning.desc')}</ContentText>
            <NonWrappingForwardLink onClick={openETraining} id="e-training-forward-link" {...positionedForwardLinkStyles}>
              {t('home:info.etraning.linkDesc')}
            </NonWrappingForwardLink>
          </ETrainingContainer>

          <SocialMediaContainer onClick={openLinkedIn} id="social-media-container">
            <IconLinkedin {...iconLinkedinStyles} />

            <ContentText {...descriptionTextStyles}>{t('home:info.socialMedia.additionalText')}</ContentText>

            <NonWrappingForwardLink onClick={openLinkedIn} id="linked-in-forward-link" {...positionedForwardLinkStyles}>
              {t('home:info.socialMedia.mainText')}
            </NonWrappingForwardLink>
          </SocialMediaContainer>
        </LeftColumnContainer>
      </Col>

      <Col {...columnStyles}>
        <ContactUsTileContainer>
          <ContactUsHeader>
            <CustomerServiceIconRoundedContainer>
              <IconCustomerService {...iconCustomerServiceStyles} />
            </CustomerServiceIconRoundedContainer>

            <ContentText {...contactUsHeaderTextStyles}>{t('home:info.contactUs.header')}</ContentText>
          </ContactUsHeader>

          <ContentText {...contactUsDescriptionTextStyles}>{t('home:info.contactUs.desc')}</ContentText>

          <ForwardLink {...forwardLinkStyles} onClick={() => navigate(paths.contactUs)} id="contact-us-forward-link">
            {t('mainLayout:contact')}
          </ForwardLink>
        </ContactUsTileContainer>
      </Col>
    </Row>
  );
};
