import { FC, useCallback, useMemo } from 'react';
import {
  CellContainer,
  StyledCompanyDetails,
  StyledTable,
  cellWithTextAlignLeftStyle,
  mediumCellContainerStyle,
  narrowCellContainerStyle,
  warningIconStyles,
  wideCellContainerStyle,
} from './styles';
import { SectionContainer, sectionHeaderStyles } from '../styles';
import { ContentText, IconWarning, tableColumn } from '@fortum/elemental-ui';
import { useTranslation } from 'react-i18next';
import { Namespace } from '@config/i18n';
import { NO_DATA_BADGE, displayedValue } from '@utils/dataOperations';
import { CreationForms, OrderSummary } from 'src/types/ordersCreation';
import { OrderCreationTransactionId } from '@models/orders';
import { compact } from 'lodash';
import { quantityColumnSortComparatorAsc } from '@utils/ordersCreation';

const renderCompanyCell = (_data: string, row: OrderSummary, error: boolean) =>
  row.serviceSummary?.company ? <StyledCompanyDetails $error={error} company={row.serviceSummary?.company} /> : null;

const renderErrorCell = (error: boolean) => (error ? <IconWarning {...warningIconStyles} /> : null);

interface SummaryTableProps {
  creationForms: CreationForms;
  failedOrders?: OrderCreationTransactionId[];
}

export const SummaryTable: FC<SummaryTableProps> = ({ creationForms, failedOrders }) => {
  const { t } = useTranslation<Namespace[]>(['orderCreation', 'wasteDetails', 'services']);

  const forms = useMemo<OrderSummary[]>(
    () =>
      creationForms.flatMap(creationForm =>
        creationForm.forms.map(orderForm => ({
          contractNo: creationForm.contractNo,
          serviceSummary: creationForm.serviceSummary,
          formIdentifier: orderForm.formIdentifier,
          ...orderForm.values,
        })),
      ),
    [creationForms],
  );

  const appendErrorColumn = !!failedOrders?.length;

  const displayError = useCallback(
    (formIdentifier: string) => !!failedOrders?.find(order => order.uuid === formIdentifier),
    [failedOrders],
  );

  const columns = useMemo(
    () =>
      compact([
        appendErrorColumn &&
          tableColumn<OrderSummary, string>(row => row.formIdentifier)({
            key: 'error-column',
            name: '',
            renderCell: data => renderErrorCell(displayError(data)),
          }),
        tableColumn<OrderSummary, string>(row => row.serviceSummary?.company?.customer ?? '')({
          key: 'summary-company',
          name: t('wasteDetails:company'),
          cellContainerStyle: wideCellContainerStyle,
          renderCell: (data, row) => renderCompanyCell(data, row, displayError(row.formIdentifier)),
          sortable: true,
        }),
        tableColumn<OrderSummary, string>(row => displayedValue(row.serviceSummary?.wasteDescription))({
          key: 'summary-waste-desc',
          name: t('wasteDetails:wasteDescription'),
          cellContainerStyle: mediumCellContainerStyle,
          sortable: true,
          renderCell: (data, row) => <CellContainer $error={displayError(row.formIdentifier)}>{data}</CellContainer>,
        }),
        tableColumn<OrderSummary, string>(row => displayedValue(row.serviceSummary?.equipmentDescription))({
          key: 'summary-eq-type',
          name: t('wasteDetails:equipmentType'),
          cellContainerStyle: mediumCellContainerStyle,
          sortable: true,
          renderCell: (data, row) => <CellContainer $error={displayError(row.formIdentifier)}>{data}</CellContainer>,
        }),
        tableColumn<OrderSummary, number>(row => row.equipmentQuantity)({
          key: 'summary-equipment-qty',
          name: t('orderCreation:summaryPage.table.estimatedQty'),
          cellContainerStyle: { ...narrowCellContainerStyle, ...cellWithTextAlignLeftStyle },
          sortable: true,
          renderCell: (data, row) => <CellContainer $error={displayError(row.formIdentifier)}>{data}</CellContainer>,
        }),
        tableColumn<OrderSummary, number | string>(row => row.estimatedWasteQuantity || NO_DATA_BADGE)({
          key: 'summary-estimated-waste-qty',
          name: t('orderCreation:summaryPage.table.estimatedWasteQty'),
          cellContainerStyle: { ...narrowCellContainerStyle, ...cellWithTextAlignLeftStyle },
          sortable: true,
          sortComparator: quantityColumnSortComparatorAsc,
          renderCell: (data, row) => <CellContainer $error={displayError(row.formIdentifier)}>{data}</CellContainer>,
        }),
        tableColumn<OrderSummary, string>(row => t(`orderCreation:form.orderType.${row.orderType}`))({
          key: 'summary-order-type',
          name: t('orderDetails:orderTypeFieldLabel'),
          cellContainerStyle: mediumCellContainerStyle,
          sortable: true,
          renderCell: (data, row) => <CellContainer $error={displayError(row.formIdentifier)}>{data}</CellContainer>,
        }),
        tableColumn<OrderSummary, string>(row => row.contractNo)({
          key: 'summary-contract-no',
          name: t('wasteDetails:contractNo'),
          cellContainerStyle: mediumCellContainerStyle,
          sortable: true,
          renderCell: (data, row) => <CellContainer $error={displayError(row.formIdentifier)}>{data}</CellContainer>,
        }),
        tableColumn<OrderSummary, string>(row => row.comments || NO_DATA_BADGE)({
          key: 'summary-comment',
          name: t('orderCreation:summaryPage.table.additionalComment'),
          cellContainerStyle: wideCellContainerStyle,
          sortable: true,
          renderCell: (data, row) => <CellContainer $error={displayError(row.formIdentifier)}>{data}</CellContainer>,
        }),
      ]),
    [forms, t, failedOrders],
  );

  return (
    <SectionContainer>
      <ContentText {...sectionHeaderStyles}>{t('summaryTableSection.header')}</ContentText>

      <StyledTable
        size="small"
        rowStyle="zebra"
        data-testid="summary-table"
        columns={columns}
        rows={forms}
        getRowKey={row => row.formIdentifier}
        $failedOrders={appendErrorColumn}
      />
    </SectionContainer>
  );
};
