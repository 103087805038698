import { DetailedUserProfile, UserProfile, UsersProfilesResponse } from '@models/usersManagement';

export const users: UserProfile[] = [
  {
    uuid: '123',
    name: 'Test 1',
    lastName: 'User 1',
    email: 'testuser1@gmail.com',
    companies: ['Neste Oyj'],
    phone: '+124',
    userType: 'EXTERNAL',
    userRole: 'USER',
  },
  {
    uuid: '124',
    name: 'Test 2',
    lastName: 'User 2',
    email: 'testuser2@gmail.com',
    companies: ['Neste Oyj'],
    phone: '+126',
    userType: 'EXTERNAL',
    userRole: 'USER',
  },
  {
    uuid: '125',
    name: 'Test 3',
    lastName: 'User 3',
    email: 'testuser3@gmail.com',
    companies: ['Adita Oy'],
    phone: '+129',
    userType: 'INTERNAL',
    userRole: 'USER',
  },
  {
    uuid: '126',
    name: 'Test 4',
    lastName: 'User 4',
    email: 'testuser4@gmail.com',
    companies: ['Neste Oyj'],
    phone: '+121',
    userType: 'INTERNAL',
    userRole: 'ADMIN',
  },
];

export const usersProfileResponse: UsersProfilesResponse = {
  metadata: {
    total: 500,
    page: 0,
  },
  response: {
    data: users,
  },
};

export const userProfiles: DetailedUserProfile[] = users.map(user => ({
  ...user,
  language: 'EN',
  status: 'Active',
  permissions: ['Order tracking and service view'],
  additionalFeaturesAccesses: [
    { name: 'CO2 report', accessGranted: false },
    { name: 'OrdersManagement', accessGranted: true },
  ],
}));
