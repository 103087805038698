import { PageNavigationHeader } from '@components/PageNavigationHeader';
import { VerticalContainer } from '@components/styles';
import { BoxProps, colors, spacing } from '@fortum/elemental-ui';
import styled from 'styled-components';

export const containerStyles: BoxProps = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.snowWhite,
  flex: 1,
  ph: spacing.xs,
  pb: spacing.xs,
};

export const FillAvailableContainer = styled(VerticalContainer)`
  flex: 1;
`;

export const OutletContainer = styled(FillAvailableContainer)`
  padding-bottom: 48px;
`;

export const NavigationHeaderWithMarginBottom = styled(PageNavigationHeader)`
  margin-bottom: ${spacing.xs};
`;
