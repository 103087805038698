import { isPlatformSupported } from '@common/browserUtil';
import { translate } from '@utils/internationalization';
import { Namespace } from '@config/i18n';
import {
  Box,
  ContentText,
  ContentTextProps,
  CssValue,
  RowKey,
  TableColumnProps,
  TableProps,
  colors,
  fontSizes,
} from '@fortum/elemental-ui';
import type { Order } from '@models/orders';
import { hasValue, NO_DATA_BADGE } from '@utils/dataOperations';
import type { FC } from 'react';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BUSINESS_PARTNER_COLUMN_WIDTH_PX,
  EQUIPMENT_TRANSLATIONS_COLUMN_MAX_WIDTH_PX,
  EQUIPMENT_TRANSLATIONS_COLUMN_MIN_WIDTH_PX,
  EXECUTION_DATE_COLUMN_MIN_WIDTH_PX,
  HANDLING_STATUS_COLUMN_MIN_WIDTH_PX,
  SITE_COLUMN_MAX_WIDTH_PX,
  SITE_COLUMN_MIN_WIDTH_PX,
  StyledOrdersTable,
  TRANSPORT_TYPE_COLUMN_MIN_WIDTH_PX,
  WASTE_DESCRIPTION_COLUMN_MAX_WIDTH_PX,
  WASTE_DESCRIPTION_COLUMN_MIN_WIDTH_PX,
  WordBreakingContentText,
} from './styles';
import { useNavigate } from 'react-router-dom';
import { paths } from '@config/routes';
import { CellPlaceholder } from '@components/CellPlaceholder';
import { getColumnsBaseConfiguration } from './config';
import { getEmptyData } from '@utils/dataPlaceholders';
import { FilteringResult } from '@components/layout/FilteringResult';
import { isNewlyCreatedOrder, isTemporaryOrderNo, getOrderExecutionDate, translateOrderStatusCode } from '@utils/orders';

interface Props {
  orders: Order[];
  paginationHeight: CssValue;
  isLoading: boolean;
  pageHeaderHeight: CssValue | undefined;
}

const cellTextCommonStyles: ContentTextProps = {
  fontSize: fontSizes.s,
};

const greyTextStyles: ContentTextProps = {
  ...cellTextCommonStyles,
  color: colors.silverGrey,
};

const emptyOrders = getEmptyData<Order>(10).map((order, index) => ({
  ...order,
  orderNo: index.toString(),
}));

export const OrdersTable: FC<Props> = memo<Props>(({ orders, paginationHeight, isLoading, pageHeaderHeight = '380px' }) => {
  const { t, i18n } = useTranslation<Namespace>('orders');
  const navigate = useNavigate();

  const columnsBaseConfig = useMemo(getColumnsBaseConfiguration, [i18n.language]);

  const placeholderColumns = useMemo<TableColumnProps<Order, RowKey>[]>(
    () => [
      {
        ...columnsBaseConfig.orderHandlingStatus,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder cellWidth={HANDLING_STATUS_COLUMN_MIN_WIDTH_PX} />,
      },
      {
        ...columnsBaseConfig.executionDate,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder cellWidth={EXECUTION_DATE_COLUMN_MIN_WIDTH_PX} />,
      },
      {
        ...columnsBaseConfig.orderType,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder />,
      },
      {
        ...columnsBaseConfig.wasteDescription,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder cellWidth={WASTE_DESCRIPTION_COLUMN_MIN_WIDTH_PX} />,
      },
      {
        ...columnsBaseConfig.transportType,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder cellWidth={TRANSPORT_TYPE_COLUMN_MIN_WIDTH_PX} />,
      },
      {
        ...columnsBaseConfig.equipmentTranslations,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder cellWidth={EQUIPMENT_TRANSLATIONS_COLUMN_MIN_WIDTH_PX} />,
      },
      {
        ...columnsBaseConfig.businessPartnerName,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder cellWidth={BUSINESS_PARTNER_COLUMN_WIDTH_PX} />,
      },
      {
        ...columnsBaseConfig.site,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder cellWidth={SITE_COLUMN_MIN_WIDTH_PX} />,
      },
      {
        ...columnsBaseConfig.orderNo,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder />,
      },
      {
        ...columnsBaseConfig.contractNo,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder />,
      },
      {
        ...columnsBaseConfig.tourNo,
        getCellData: () => '',
        renderCell: () => <CellPlaceholder />,
      },
    ],
    [orders, i18n.language],
  );

  const columns = useMemo<TableColumnProps<Order, RowKey>[]>(
    () => [
      {
        ...columnsBaseConfig.orderHandlingStatus,
        getCellData: (row: { orderHandlingStatus: string }) => row.orderHandlingStatus,
        renderCell: data => {
          const textStyles = isNewlyCreatedOrder(data.toString()) ? greyTextStyles : cellTextCommonStyles;

          return (
            <ContentText {...textStyles} minWidth={HANDLING_STATUS_COLUMN_MIN_WIDTH_PX}>
              {translateOrderStatusCode(data.toString()) || NO_DATA_BADGE}
            </ContentText>
          );
        },
      },
      {
        ...columnsBaseConfig.executionDate,
        getCellData: (row: { executionDate: string }) => row.executionDate.slice(0, 10),
        renderCell: (_, row: { executionDate: string; orderHandlingStatus: string }) => {
          return (
            <ContentText {...cellTextCommonStyles} minWidth={EXECUTION_DATE_COLUMN_MIN_WIDTH_PX}>
              {getOrderExecutionDate(row)}
            </ContentText>
          );
        },
      },
      {
        ...columnsBaseConfig.orderType,
        getCellData: (row: { orderType: string }) => translate(row.orderType.toUpperCase(), 'domain', 'orderTypes'),
      },
      {
        ...columnsBaseConfig.wasteDescription,
        getCellData: (row: { wasteDescription: string }) => row.wasteDescription,
        renderCell: data => {
          return (
            <WordBreakingContentText
              {...cellTextCommonStyles}
              minWidth={WASTE_DESCRIPTION_COLUMN_MIN_WIDTH_PX}
              maxWidth={WASTE_DESCRIPTION_COLUMN_MAX_WIDTH_PX}
            >
              {data.toString()}
            </WordBreakingContentText>
          );
        },
      },
      {
        ...columnsBaseConfig.transportType,
        getCellData: (row: { transportType: string }) => translate(row.transportType, 'domain', 'transportType'),
        renderCell: data => {
          return (
            <ContentText {...cellTextCommonStyles} minWidth={TRANSPORT_TYPE_COLUMN_MIN_WIDTH_PX}>
              {data.toString()}
            </ContentText>
          );
        },
      },
      {
        ...columnsBaseConfig.equipmentTranslations,
        getCellData: (row: { equipmentTranslations: string }) => row.equipmentTranslations,
        renderCell: data => {
          return (
            <ContentText
              {...cellTextCommonStyles}
              minWidth={EQUIPMENT_TRANSLATIONS_COLUMN_MIN_WIDTH_PX}
              maxWidth={EQUIPMENT_TRANSLATIONS_COLUMN_MAX_WIDTH_PX}
            >
              {data.toString()}
            </ContentText>
          );
        },
      },
      {
        ...columnsBaseConfig.businessPartnerName,
        getCellData: (row: { businessPartnerName: string }) => row.businessPartnerName,
        renderCell: data => {
          return (
            <ContentText {...cellTextCommonStyles} width={BUSINESS_PARTNER_COLUMN_WIDTH_PX}>
              {data.toString()}
            </ContentText>
          );
        },
      },
      {
        ...columnsBaseConfig.site,
        getCellData: (row: { address: string }) => row.address,
        renderCell: (_, row) => {
          return (
            <Box minWidth={SITE_COLUMN_MIN_WIDTH_PX} maxWidth={SITE_COLUMN_MAX_WIDTH_PX}>
              {hasValue(row.locationSpecificName) && (
                <ContentText {...cellTextCommonStyles}>{row.locationSpecificName}</ContentText>
              )}
              {hasValue(row.address) && <ContentText {...cellTextCommonStyles}>{row.address}</ContentText>}
              {hasValue(row.exactNameTranslations) && (
                <ContentText {...cellTextCommonStyles}>{row.exactNameTranslations}</ContentText>
              )}
              {hasValue(row.city) && <ContentText {...cellTextCommonStyles}>{row.city}</ContentText>}
            </Box>
          );
        },
      },
      {
        ...columnsBaseConfig.orderNo,
        getCellData: (row: { orderNo: string }) => (isTemporaryOrderNo(row.orderNo) ? NO_DATA_BADGE : row.orderNo),
      },
      {
        ...columnsBaseConfig.contractNo,
        getCellData: (row: { contractNo: string }) => row.contractNo,
      },
      {
        ...columnsBaseConfig.tourNo,
        getCellData: (row: { tourNo: string }) => row.tourNo,
      },
    ],
    [orders, i18n.language],
  );

  if (!isLoading && orders.length === 0) {
    return <FilteringResult message={t('table.noOrdersToShow')} />;
  }

  const stateDependantTableProps: Pick<TableProps<Order>, 'rows' | 'columns' | 'onRowClick'> = isLoading
    ? {
        rows: emptyOrders,
        columns: placeholderColumns,
        onRowClick: undefined,
      }
    : {
        rows: orders,
        columns,
        onRowClick: (row: { orderNo: string }) => navigate(`${paths.orders}/${row.orderNo}`),
      };

  return (
    <StyledOrdersTable
      id="orders-table"
      size="medium"
      rowStyle="zebra"
      stickyHeader={isPlatformSupported('Chrome')}
      $pageHeaderHeight={pageHeaderHeight}
      $paginationHeight={paginationHeight}
      getRowKey={(row: { orderNo: string }) => row.orderNo}
      {...stateDependantTableProps}
    />
  );
});

OrdersTable.displayName = 'OrdersTable';
