import { Namespace } from '@config/i18n';
import { ChipItem, IconSearch } from '@fortum/elemental-ui';
import { ChangeEvent, Dispatch, FC, forwardRef, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Container,
  FiltersContainer,
  inputFieldStyles,
  multiselectStyles,
  StyledCommonHeader,
  StyledFiltersAccordion,
} from './styles';
import { Multiselect } from '@components/Multiselect';
import { mapToSelectItems } from '@utils/dataOperations';
import { compact } from 'lodash';
import { InputField } from '@components/InputField';

interface FiltersProps {
  setSelectedCompanies: Dispatch<SetStateAction<string[]>>;
  selectedCompanies: string[];
  companies: string[];
  searchText: string;
  setSearch: (value: string) => void;
}

const Filters: FC<FiltersProps> = ({ setSelectedCompanies, selectedCompanies, companies, searchText, setSearch }) => {
  const { t } = useTranslation<Namespace[]>(['usersManagement', 'common']);
  const items = useMemo(() => mapToSelectItems(companies), [companies]);

  const setSearchValue = useCallback((ev: ChangeEvent<HTMLInputElement>) => setSearch(ev.target.value), [setSearch]);

  return (
    <FiltersContainer>
      <Multiselect
        id="companies-multiselect"
        name="companiesMultiselect"
        label={t('usersManagement:usersList.company.header')}
        placeholder={t('common:all')}
        onSelectedItemsChange={setSelectedCompanies}
        selected={selectedCompanies}
        items={items}
        {...multiselectStyles}
      />

      <InputField
        name="search-input-field"
        label={t('usersManagement:filters.search.label')}
        placeholder={t('common:search')}
        value={searchText}
        onChange={setSearchValue}
        icon={<IconSearch />}
        {...inputFieldStyles}
      />
    </FiltersContainer>
  );
};

type PageHeaderProps = Omit<FiltersProps, 'onCompaniesSelected'> & {
  setSelectedCompanies: Dispatch<SetStateAction<string[]>>;
};

const SEARCH_CHIP_VALUE = 'search';

const createChipsItems = (companies: string[], searchText: string) => {
  const companyChips: ChipItem<string>[] = companies.map(company => ({ value: company, label: company }));

  return compact([...companyChips, !!searchText && { value: SEARCH_CHIP_VALUE, label: searchText }]);
};

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(({ setSearch, setSelectedCompanies, ...props }, ref) => {
  const { t } = useTranslation<Namespace[]>(['usersManagement']);

  const resetAllFilters = useCallback(() => {
    setSelectedCompanies([]);
    setSearch('');
  }, [setSelectedCompanies, setSearch]);

  const removeChipItem = useCallback(
    ({ value }: ChipItem<string>) => {
      if (value === SEARCH_CHIP_VALUE) {
        setSearch('');
        return;
      }

      setSelectedCompanies(prev => prev.filter(selectedCompany => selectedCompany !== value));
    },
    [setSearch, setSelectedCompanies],
  );

  const chipsItems = useMemo<ChipItem<string>[]>(
    () => createChipsItems(props.selectedCompanies, props.searchText),
    [props.selectedCompanies, props.searchText],
  );

  return (
    <Container ref={ref}>
      <StyledCommonHeader
        header={t('appMenuItem.label')}
        componentRight={<Filters setSearch={setSearch} setSelectedCompanies={setSelectedCompanies} {...props} />}
      />

      {chipsItems.length > 0 && (
        <StyledFiltersAccordion chipsItems={chipsItems} clearAllFilters={resetAllFilters} removeFilter={removeChipItem} />
      )}
    </Container>
  );
});

PageHeader.displayName = 'PageHeader';
