import { QueryKeys } from '@common/query';
import { fetchUsersProfilesFilters } from '@data/api/usersManagement';
import { QueryOptions } from '@data/hooks/types';
import { UsersProfilesFilters, UsersProfilesFiltersWithSearch } from '@models/usersManagement';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';

const usersProfilesFiltersQuery = (selectedFilters: UsersProfilesFiltersWithSearch): QueryOptions<UsersProfilesFilters> => ({
  queryKey: [QueryKeys.usersProfilesFilters, selectedFilters],
  queryFn: () => fetchUsersProfilesFilters(selectedFilters),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useUsersProfilesFilters = (selectedFilters: UsersProfilesFiltersWithSearch) =>
  useQuery<UsersProfilesFilters, Error>(usersProfilesFiltersQuery(selectedFilters));
