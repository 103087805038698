import { UsersProfilesFiltersWithSearch, UsersProfilesResponse, UsersProfilesFilters } from '@models/usersManagement';
import { userProfiles } from '@models/__fixtures__/usersManagement';
import { DetailedUserProfile } from '@models/usersManagement';
import { UsersProfilesRequestParams } from 'src/types/usersManagement';
import { urls } from './config';
import { postData } from '@utils/data';
import { Headers as HeadersConstants, MimeTypes } from 'http-constants-ts';

const appJsonContentTypeHeader = new Map([[HeadersConstants.CONTENT_TYPE, MimeTypes.APPLICATION_JSON]]);

//TODO: implement
export const fetchUserProfile = async (userId: string): Promise<DetailedUserProfile> =>
  userProfiles.find(userProfile => userProfile.uuid === userId) || userProfiles[0];

//TODO: send filters with the request once it is implemented on the BE
export const fetchUsersProfiles = async (params: UsersProfilesRequestParams): Promise<UsersProfilesResponse> => {
  if (!urls.usersList) {
    throw Error('Users list URL not found');
  }
  const url = `${urls.usersList}?page=${params.activePage - 1}&size=${params.numberOfPageElements}`;

  return postData<UsersProfilesResponse>(url, JSON.stringify({}), appJsonContentTypeHeader);
};

//TODO: implement
export const fetchUsersProfilesFilters = async (
  _selectedFilters: UsersProfilesFiltersWithSearch,
): Promise<UsersProfilesFilters> => Promise.resolve({ companies: ['Company 1', 'Company 2', 'Company 3'] });
