import { QueryKeys } from '@common/query';
import { fetchContractsSites } from '@data/api/contract';
import { ContractsSitesResponse } from '@models/contract';
import { UserAccess } from '@models/user';
import { useQuery } from '@tanstack/react-query';
import { shouldRetry } from '@utils/data';
import { getUserAccessInfo, isUserAccessOfInternalAdmin } from '@utils/user';
import { useTranslation } from 'react-i18next';
import { ContractFiltersKeys } from 'src/types/services';
import { QueryOptions } from './types';

const contractsSitesQuery = (
  filtersKeys: ContractFiltersKeys,
  userAccess: UserAccess,
  additionalFilteringForOrdersCreation: boolean,
  language: string,
): QueryOptions<ContractsSitesResponse> => ({
  queryKey: isUserAccessOfInternalAdmin(userAccess)
    ? [
        QueryKeys.contractsSitesAdmin,
        userAccess.selectedBusinessPartners.map(bp => bp.id).join(','),
        filtersKeys,
        language,
        additionalFilteringForOrdersCreation,
      ]
    : [QueryKeys.contractsSites, filtersKeys, language, additionalFilteringForOrdersCreation],
  queryFn: () => fetchContractsSites(filtersKeys, userAccess, language, additionalFilteringForOrdersCreation),
  refetchOnWindowFocus: false,
  retry: shouldRetry,
});

export const useContractsSites = (filtersKeys: ContractFiltersKeys, additionalFilteringForOrdersCreation = false) => {
  const { i18n } = useTranslation();

  return useQuery<ContractsSitesResponse, Error>(
    contractsSitesQuery(filtersKeys, getUserAccessInfo(), additionalFilteringForOrdersCreation, i18n.language),
  );
};
